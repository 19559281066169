import axios from 'axios'
import closeIcon from "../resource/img/icons/close-icon-white.svg"
import React from "react";

function ContactForm(props) {
    const closeImg = props.close ? <img className="close" src={closeIcon} alt="closeIcon" onClick={()=>props.openCloseRequestForm()}/> : ''
    return (
        <form className="contactForm" action="/mail.php" method="post">
            <div className="flex-wrapper">
                <h3 className="contactForm-header">Request a free quote</h3>
                {closeImg}
            </div>
            <label className="inputLabel" htmlFor="serviceNeed">Service needed*</label>
            <select className="contactForm-input" name="serviceNeed" id="serviceNeed" required >
                <option value="">General building job</option>
                <option value="residential">Residential</option>
                <option value="commercial">Commercial</option>
            </select>
            <label className="inputLabel" htmlFor="name">Name*</label>
            <input className="contactForm-input" type="text" name="name" id="name" required/>
            <label className="inputLabel" htmlFor="email">Email*</label>
            <input className="contactForm-input" type="email" name="email" id="email" required/>
            <label className="inputLabel" htmlFor="phone">Phone:</label>
            <input className="contactForm-input" type="phone" name="phone" id="phone" required/>
            <button type="submit" className="contactForm-submit">Submit</button>
        </form>
    )
}

export default ContactForm